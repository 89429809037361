











































import { Component, Vue, Prop } from "vue-property-decorator";
// @ts-ignore
import { Container, Draggable } from "vue-smooth-dnd";
import { enterpriseApi } from "@/api/enterprise";
import { UserModule } from "@/store/modules/user";
@Component({ components: { Container, Draggable } })
export default class SortFields extends Vue {
  @Prop() detail: any;
  visible: boolean = false;
  loading = false;

  handleClose() {
    this.visible = false;
  }
  async open() {
    this.loading = true;
    this.visible = true;
    try {
      await this.getFields();
    } catch (error) {}
    this.loading = false;
  }
  onDrop(dropResult: any) {
    const { removedIndex, addedIndex } = dropResult;
    const dropItem = this.fields[removedIndex];
    this.fields.splice(removedIndex, 1);
    this.fields.splice(addedIndex, 0, dropItem);
    this.fields = [...this.fields];
  }
  fields: any = [];
  created() {}
  async getFields() {
    const [{ value: sortRes = [] }, { value: res = [] }]: any =
      await Promise.allSettled([
        enterpriseApi.getFieldsSort({
          enterpriseId: this.detail?.id,
        }),
        enterpriseApi.getFields({
          enterpriseId: this.detail?.id,
        }),
      ]);
    const fields = [
      ...(sortRes?.length ? sortRes : []),
      ...res
        ?.reduce((s: any[], c: any) => {
          if (c instanceof Array) {
            return [...s, ...c];
          }
          return [...s, c];
        }, [])
        .filter(
          (child: any) => !(sortRes?.length ? sortRes : [])?.find((item: any) => child.key === item.key)
        ),
    ];
    this.fields = fields?.filter(({ key }: any) => key !== "extend_info");
  }
  async comfirmSort() {
    this.loading = true;
    try {
      await enterpriseApi.fieldsSort({
        enterpriseId: this.detail?.id,
        columnsSort: this.fields.map((child: any, index: number) => ({
          ...child,
          index,
        })),
      });
    } catch (error) {}
    this.visible = false;
    this.loading = false;
  }
}
