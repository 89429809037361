



























































































import { Component, Vue, Ref } from "vue-property-decorator";
import {
  processApis,
  IProcessQueryParams,
  IProcessPagingResult,
} from "@/api/process";
import Pagination from "@/components/Pagination/index.vue";
import SearchHeader from "@/components/SearchHeader/index.vue";
import ProcessCreate from "./components/create-process.vue";
import { userApi } from "@/api/user";

import { enterpriseApi } from "@/api/enterprise";
@Component({
  components: { Pagination, SearchHeader, ProcessCreate },
})
export default class extends Vue {
  @Ref("process-create") processCreate!: ProcessCreate;
  ens: any = [];
  users: any = [];

  private result: IProcessPagingResult = {
    page: 1,
    perPage: 20,
    total: 0,
    items: [],
  };

  private loading = false;
  private params: IProcessQueryParams = { page: 1, perPage: 20, q: "" };

  created() {
    this.getItems();
    this.getUsers();
    this.getEns();
  }

  get formatterEns() {
    // @ts-ignore
    return (id) => this.users?.find((user) => user.id === id)?.name;
  }

  get formatterUser() {
    // @ts-ignore
    return (id) => this.ens?.find((en) => en.id === id)?.name;
  }

  getUsers() {
    userApi
      // @ts-ignore
      .getUsers({ page: 1, perPage: 9999 })
      .then((res) => (this.users = res?.items));
  }
  getEns() {
    enterpriseApi
      .getEnterprises({ page: 1, perPage: 9999 })
      .then((res) => (this.ens = res?.items));
  }

  private getItems() {
    this.loading = true;
    processApis
      .search(this.params)
      .then((r) => {
        this.result = r;
      })
      .catch((e) => {
        this.$message.error(e.message);
      })
      .finally(() => {
        setTimeout(() => {
          this.loading = false;
        }, 0.5 * 1000);
      });
  }
}
