<template>
  <div>
    <el-button type="primary" @click="jump">点击跳转至小程序</el-button>
    <el-button type="primary" @click="close">close</el-button>
  </div>
</template>

<script>
import {Component, Vue} from "vue-property-decorator";

@Component
export default class Wx extends Vue {
  mounted() {
    this.jump()
  }

  jump() {
    if (this.ticket === "test") {
      this.$message.success("测试跳转")
      return
    }
    window.location.href = "https://wxaurl.cn/" + this.ticket
  }

  get ticket() {
    return this.$route.query.ticket
  }
}
</script>

<style scoped>

</style>
