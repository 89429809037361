









































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { enterpriseApi, IEnterpriseDetail, IModifyEnterpriseParam } from '@/api/enterprise'

@Component
export default class EnterpriseModify extends Vue {
  @Prop({ required: true }) afterModify!: Function
  params = {} as IModifyEnterpriseParam
  detail = { owner: { name: '' } } as IEnterpriseDetail
  load = false
  visible = false
  imageVisible = false
  logoList = [] as {name: string, url: string}[]
  rules = {
    address: [
      { required: true, message: '请输入地址', trigger: 'blur' }
    ]
  }

  show(detail: IEnterpriseDetail) {
    this.visible = true
    this.detail = detail
    // @ts-ignore
    this.params = {
      name: detail.name,
      description: detail.description,
      address: detail.address,
      logo: detail.logo
    }
    if (this.detail.logo) {
      this.logoList = [{ url: this.uploadUrl + '/' + this.detail.logo, name: this.detail.logo.split('/')[1] }]
    }
  }

  doModify() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$forceUpdate()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.modifyEnterpriseRef.validate((valid: boolean) => {
      if (!valid) {
        this.$message.warning('请检查表单')
        return
      }
      this.load = true
      enterpriseApi.modifyEnterprise(this.params).then(() => {
        this.$message.success('修改成功')
        this.load = false
        this.visible = false
        this.afterModify()
      }).catch((e) => {
        this.$message.error(`修改失败, ${e.message}`)
        this.load = false
      })
    })
  }

  get uploadUrl(): string {
    return process.env.VUE_APP_BASE_API + '/image'
  }

  beforeUpload(file: File) {
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.$message.error('格式只能是JPG格式')
      return false
    }
    if (file.size / 1024 / 1024 > 5) {
      this.$message.error('不得超过5M')
      return false
    }
  }

  handleSuccess(res: any) {
    if (!res.success) {
      this.$message.error(`上传图片失败, ${res.message}`)
      return
    }
    this.params.logo = res.data
    this.logoList = [{ url: this.uploadUrl + '/' + res.data, name: res.data.split('/')[-1] }]
    this.$forceUpdate()
  }

  handleImageShow() {
    this.imageVisible = true
  }

  handleRemove() {
    this.params.logo = ''
    this.logoList = [] as {name: string, url: string}[]
    this.$forceUpdate()
  }

  mounted() {
    //
  }
}
