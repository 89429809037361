





































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  IDepartmentParams,
  departmentApi,
  IDepartments,
  ICreateDepartmentParams,
  IModifyDepartmentParams,
  IDepartment,
} from "@/api/department";
import { UserModule } from "@/store/modules/user";

import {
  enterpriseApi,
  IGetEnterprisesParam,
  IEnterprises,
} from "@/api/enterprise";

import { userApi, IUsers, IUserParams } from "@/api/user";

import PERMISSION from "@/constants/permission";

@Component
export default class Department extends Vue {
  load = false;
  params = { page: 1, perPage: 15 } as IDepartmentParams;
  departments = {} as IDepartments;
  enterprises = {} as IEnterprises;
  users = {} as IUsers;
  visible = false;
  getEnterprisesLoading = false;
  title = "";
  createModifyParams = {} as ICreateDepartmentParams | IModifyDepartmentParams;
  createModifyLoad = false;
  condition: any = {};
  rules = {
    name: [
      { required: true, message: "请输入科室名称", trigger: "blur" },
      { min: 3, max: 128, message: "长度在 3 到 128 个字符", trigger: "blur" },
    ],
    address: [{ required: true, message: "请输入地址", trigger: "blur" }],
    enterpriseId: [{ required: true, message: "请选择企业", trigger: "blur" }],
  };

  formatUsers(userIds: any) {
    let users = []
    for (const userId of userIds) {
      const user = this.users.items.find((item) => {return item.id === userId})
      if (!user) {
        continue
      }
      users.push(user.name)
    }
    return users.join("、");
  }

  formatUser(userId: any) {
    const user = this.users.items.find((item) => {return item.id === userId})
    return user ? user.name : "/"
  }

  get PERMISSION() {
    return PERMISSION;
  }

  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }

  mounted() {
    // this.getDepartments();
    this.getEnterprises("");
    this.getUsers("");
  }

  onRefresh(condition: any) {
    this.condition = condition;
    this.getDepartments();
  }
  deleteDepartment(row: any) {
    this.$confirm(`是否继续删除科室 ${row.name}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        departmentApi
          .deleteDepartment(row.id)
          .then(() => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.getDepartments();
          })
          .catch((error) => {
            this.$message({
              type: "error",
              message: error,
            });
          });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }

  get extraConfig() {
    return [
      {
        key: "enterpriseId",
        label: "企业",
        type: "select",
        options: this.enterprises?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择企业",
        hidden: !this.isSuperAdmin,
      },
    ];
  }

  getDepartments() {
    this.load = true;
    departmentApi
      .getDepartments({
        ...this.params,
        ...this.condition,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      })
      .then((data) => {
        this.departments = data;
      })
      .catch((e) => {
        this.$message.warning(`获取科室失败, ${e}`);
      })
      .finally(() => {
        this.load = false;
      });
  }

  getEnterprises(query: string) {
    this.getEnterprisesLoading = true;
    enterpriseApi
      .getEnterprises({
        name: query,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IGetEnterprisesParam)
      .then((data) => {
        this.enterprises = data;
      })
      .catch((e) => {
        this.$message.warning(`获取企业列表失败, ${e}`);
      })
      .finally(() => (this.getEnterprisesLoading = false));
  }

  getUsers(query: string) {
    userApi
      .getUsers({
        name: query,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
        page: 1,
        perPage: 9999,
      } as IUserParams)
      .then((data) => {
        this.users = data;
      })
      .catch((e) => {
        this.$message.warning(`获取用户列表失败, ${e}`);
      });
  }

  handleSizeChange(size: number) {
    this.params.perPage = size;
    this.getDepartments();
  }

  handlePageChange(page: number) {
    this.params.page = page;
    this.getDepartments();
  }

  createDepartment() {
    this.title = "创建科室";
    this.createModifyParams = {
      enterpriseId: UserModule.isSuperAdmin
        ? undefined
        // @ts-ignore
        : UserModule?.user?.enterprise?.id,
    } as ICreateDepartmentParams;
    this.visible = true;
    this.$forceUpdate();
  }

  modifyDepartment(row: IDepartment) {
    this.title = `编辑${row.name}科室`;
    // @ts-ignore
    this.createModifyParams = {...row};
    this.enterprises = this.enterprises?.items?.find(
      ({ id }) => id === row.enterpriseId
    )
      ? this.enterprises
      : ({
          items: [
            { id: row.enterpriseId, name: row.enterpriseName },
            ...(this.enterprises?.items || []),
          ],
        } as IEnterprises);
    this.visible = true;
    this.$forceUpdate();
  }

  doAction() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.createModifyRef.validate((valid: boolean) => {
      if (!valid) {
        this.$message.warning("请检查表单");
        return;
      }
      this.createModifyLoad = true;
      let func = departmentApi.createDepartment;
      // eslint-disable-next-line no-prototype-builtins
      if (this.createModifyParams.hasOwnProperty("id")) {
        // @ts-ignore
        func = departmentApi.modifyDepartment;
      }
      func(this.createModifyParams)
        .then(() => {
          this.$message.success(this.title);
          this.visible = false;
          this.getDepartments();
        })
        .catch((e) => {
          this.$message.warning(`${this.title}, ${e}`);
        })
        .finally(() => {
          this.createModifyLoad = false;
        });
    });
  }
}
