var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-container"},[_c('div',[_c('search-bar',{attrs:{"uuid":"enter","queryKey":"name","queryPlaceholder":"请输入企业名称搜索","extraConfig":_vm.extraConfig,"loading":_vm.queryLoad},on:{"refresh":_vm.onRefresh}})],1),_c('el-row',[_vm._v(" 企业列表 "),_c('div',{staticStyle:{"float":"right"}},[_c('el-row',[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.PERMISSION.ENTERPRISE_ADD),expression:"PERMISSION.ENTERPRISE_ADD"}],attrs:{"size":"mini","type":"primary"},on:{"click":_vm.createEnterprise}},[_vm._v("新建")])],1)],1)]),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.queryLoad),expression:"queryLoad"}],staticStyle:{"margin-right":"20px"},attrs:{"data":_vm.enterprises.items,"stripe":""}},[_c('el-table-column',{attrs:{"label":"名称","prop":"name"}}),_c('el-table-column',{attrs:{"label":"用户名称","prop":"telephoneOwner"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.owner.name)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"登录账号"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.owner.telephone)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 0)?_c('el-tag',{attrs:{"size":"small","type":"primary"}},[_vm._v("初始化")]):_vm._e(),(row.status === 1)?_c('el-tag',{attrs:{"size":"small","type":"success"}},[_vm._v("使用中")]):_vm._e(),(row.status === 2)?_c('el-tag',{attrs:{"size":"small","type":"warning"}},[_vm._v("锁定")]):_vm._e(),(row.status === 3)?_c('el-tag',{attrs:{"size":"small","type":"info"}},[_vm._v("废弃")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"创建时间","prop":"gmtCreate"}}),_c('el-table-column',{attrs:{"label":"修改时间","prop":"gmtModify"}}),_c('el-table-column',{attrs:{"label":"操作","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.toEnterpriseDetail(row.name)}}},[_vm._v("详情")])]}}])})],1),_c('el-pagination',{staticStyle:{"float":"right"},attrs:{"current-page":_vm.queryParams.page,"page-sizes":[15, 50, 100],"page-size":15,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.enterprises.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handlePageChange}}),_c('EnterpriseCreate',{ref:"enterprise-create",attrs:{"after-success":_vm.getEnterprises}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }