
















































































































































































































import { Component, Vue, Ref } from 'vue-property-decorator'
import {
  processApis,
  IProcessQueryParams,
  IProcessPagingResult,
} from '@/api/process'
import { roleApi } from '@/api/role'
import { orderApi } from '@/api/order'
import { UserModule } from '@/store/modules/user'
import { departmentApi } from '@/api/department'
import PERMISSION from '@/constants/permission'
import { userApi } from '@/api/user'
import Pagination from '@/components/Pagination/index.vue'
import SearchHeader from '@/components/SearchHeader/index.vue'
import GenerateForm from '@/components/VueFormMaking/components/GenerateForm.vue'
import { IAdjustForm, IInsForm } from '@/components/adjust_form'
import DepartmentSelect from '@/components/Select/DepartmentSelect.vue'
import EmployeeSelect from '@/components/Select/EmployeeSelect.vue'

@Component({
  components: {
    Pagination,
    SearchHeader,
    GenerateForm,
    DepartmentSelect,
    EmployeeSelect
  },
})
export default class Simple extends Vue {
  @Ref('process-create') processCreate!: any

  private result: IProcessPagingResult = {
    page: 1,
    perPage: 20,
    total: 0,
    items: [],
  }
  private visible: boolean = false
  private loading: boolean = false
  private createModifyLoad: boolean = false
  private processObj: any = {}
  creatLoading = false
  private params: IProcessQueryParams = {
    page: 1,
    perPage: 20,
    q: ''
  }
  condition: any = {}
  confirmLoading: boolean = false
  departments: any = []
  orderVisible: boolean = false
  widgetModels = {}
  users: any = []
  roles: any = []
  formValues: any = {}
  form: any = {}
  currentProcess: any = {}
  TYPE_MAP: any = {
    PERSON: 'person',
    DEPARTMENT: 'department',
    ROLE: 'role',
  }
  TYPE_LABEL_MAP: any = {
    [this.TYPE_MAP.PERSON]: '审批人',
    [this.TYPE_MAP.DEPARTMENT]: '审批科室',
    [this.TYPE_MAP.ROLE]: '审批角色',
  }
  TYPE_OPTION_KEY_MAP: any = {
    [this.TYPE_MAP.PERSON]: 'users',
    [this.TYPE_MAP.DEPARTMENT]: 'departments',
    [this.TYPE_MAP.ROLE]: 'roles',
  }
  typeOptions = [
    {
      key: this.TYPE_MAP.PERSON,
      label: '人员',
    },
    {
      key: this.TYPE_MAP.DEPARTMENT,
      label: '科室',
    },
    {
      key: this.TYPE_MAP.ROLE,
      label: '角色',
    },
  ]

  get ignoreSelectStatus() {
    // @ts-ignore
    return {status: UserModule.config?.noUseStatus}
  }

  get orderWidth() {
    return this.currentProcess?.name === '调整' || this.currentProcess?.name === "新增" ? '800px' : '480px'
  }

  get valueOptions() {
    return (key: string) =>
      (this as any)?.[this.TYPE_OPTION_KEY_MAP?.[this.formValues?.[key]]]
  }

  get formatterLabel() {
    return (key: string) => this.TYPE_LABEL_MAP[this.formValues[key]]
  }

  get PERMISSION() {
    return PERMISSION
  }

  get isShow() {
    return (item: any) =>
      Object.keys(this.TYPE_MAP).find(
        (key) => this.TYPE_MAP[key] === item.assignType
      )
  }

  onRefresh(condition: any) {
    this.condition = condition
    this.getItems()
  }

  async onOpenOrder(process: any) {
    const data = await processApis.getForm(process.form_id)
    const {
      // @ts-ignore
      list,
      // @ts-ignore
      config
    } = data.structure
    const startNode = process?.structure?.nodes?.find(
      // @ts-ignore
      (node) => node.clazz === 'start'
    )
    this.form = {
      config,
      // @ts-ignore
      list: list.filter((item) =>
        startNode?.visiableFields?.split(',')?.includes(item.model)
      ),
    }
    this.orderVisible = true
    this.currentProcess = process
  }

  async doCreateOrder() {
    this.creatLoading = true
    try {
      // @ts-ignore
      const formData = await this.$refs.generateForm.getData()
      if (this.currentProcess.name === "调整") {  // 调整
        formData["tz_type"] = "其他"
      }
      await orderApi.createOrder({
        processId: this.currentProcess?.id,
        formData,
      })
      this.orderVisible = false
      this.$message.success('发起成功')
    } catch (error) {
      this.$message.error(error)
    }
    this.creatLoading = false
  }

  onChangeType(key: string) {
    this.formValues[key] = []
  }

  private getDepartments() {
    departmentApi
      .getDepartments({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
        perPage: 99999,
        baseOnly: 1
      } as any)
      .then((data) => {
        this.departments = data
      })
      .catch((e) => {
        this.$message.warning(`获取科室列表失败, ${e}`)
      })
  }

  private getUsers() {
    userApi
      .getUsers({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
        perPage: 99999,
        baseOnly: 1
      } as any)
      .then((data) => {
        this.users = data
      })
      .catch((e) => {
        this.$message.warning(`获取用户列表失败, ${e}`)
      })
  }

  getRoles() {
    roleApi
      .getRoles({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
        perPage: 99999,
        baseOnly: 1
      } as any)
      .then((data) => {
        this.roles = data
      })
      .catch((e) => {
        this.$message.warning(`获取角色失败, ${e}`)
      })
      .finally(() => {
      })
  }

  created() {
    // this.getItems();
    // if (UserModule?.user?.id) {
    //   this.insSelectCondition = { custodianId: UserModule?.user?.id }
    // }
    this.getUsers()
    this.getDepartments()
    this.getRoles()
  }

  async onOpenEdit(processId: number) {
    await this.getProcess(processId)
    this.visible = true
  }

  doAction() {
    // @ts-ignore
    this.$refs.simplePocessRef.validate(async(valid: boolean) => {
      if (!valid) {
        this.$message.warning('请检查表单')
        return
      }
      const data: any = { ...this.processObj }
      Object.keys(this.formValues).forEach((key) => {
        const [id, k] = key?.split('_')
        if (!data.structure.nodes) return
        data.structure.nodes = data?.structure?.nodes?.map((node: any) => {
          if (node.id === id) {
            node[k] = this.formValues[key]
            return node
          }
          return node
        })
      })
      this.confirmLoading = true
      try {
        await processApis.updateProcess(this.processObj.id, data)
        this.$message.success(`编辑成功`)
      } catch (error) {
      }
      this.confirmLoading = false
      this.visible = false
    })
  }

  get processList() {
    const {
      edges = [],
      nodes = []
    } = this.processObj?.structure || {}
    const times = edges.reduce((s: any, c: any) => {
      if (s?.includes(c.target)) return [...s]
      if (s?.length) {
        return [...s, c.target]
      }
      return [c.source, c.target]
    }, [])
    return times?.map((id: string) =>
      nodes?.find((child: any) => child.id === id)
    )
  }

  private async getProcess(processId: number) {
    try {
      this.formValues = {}
      this.processObj = await processApis.getProcess(processId)
      const { nodes = [] } = this.processObj?.structure || {}
      nodes.forEach((child: any) => {
        if (this.isShow(child)) {
          this.formValues = {
            ...this.formValues,
            [`${child.id}_assignType`]: child.assignType,
            [`${child.id}_assignValue`]: child.assignValue,
          }
        }
      })
    } catch (err: any) {
    }
  }

  private getItems() {
    this.loading = true
    const that = this
    processApis
      .search({ ...this.params, ...this.condition })
      .then((r) => {
        this.result = r
      })
      .catch((e) => {
        this.$message.error(e.message)
      })
      .finally(() => {
        setTimeout(() => {
          that.loading = false
        }, 0.5 * 1000)
      })
  }
}
