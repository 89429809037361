






































































































































































































































































































































































































































import { Component, Ref, Vue } from 'vue-property-decorator'
import EnterpriseModify from '@/views/enterprise/EnterpriseModify.vue'
import {
  enterpriseApi,
  IEnterpriseDetail,
  IInsEntryCol,
  IModifyEnterpriseParam,
} from '@/api/enterprise'
import { IRoleParams, roleApi } from '@/api/role'
import { UserModule } from '@/store/modules/user'
import SortFields from './SortFields.vue'
import PERMISSION from '@/constants/permission'
import { ICreateInsNumber, IInsNumberDetail, IInsNumberParams, IInsNumberResp, insNumberApi } from '@/api/ins_number'
import Pagination from '@/components/Pagination/index.vue'

@Component({
  components: {
    EnterpriseModify,
    SortFields,
    Pagination
  }
})
export default class EnterpriseDetail extends Vue {
  @Ref('modifyEnterprise') enterpriseModify!: EnterpriseModify
  loading = false
  enterpriseDetail = { owner: { name: '' } } as IEnterpriseDetail
  modifyAttr = false
  modifyLoading = false
  lastAttrs = [] as IInsEntryCol[]
  roles = []
  enterpriseName: string = ':enterpriseName'
  repairRolesVisible = false
  repairRolesLoading = false
  repairRoles = []
  modifySelectAttrs = [] as { value: string, code?: string }[]
  visibleSelectAttrs = false

  action = '新增'
  insNumberParams = { perPage: 10 } as IInsNumberParams
  insNumberVisible = false
  insNumberLoading = false
  insNumbers = {} as IInsNumberResp
  addInsNumberVisible = false
  addInsNumberLoading = false
  addInsNumberParams = {} as ICreateInsNumber
  addInsNumberRules = {
    name: [{
      required: true,
      message: '请输入',
      trigger: 'blur'
    }],
    number: [{
      required: true,
      message: '请输入',
      trigger: 'blur'
    }],
    international_number: [{
      required: true,
      message: '请输入',
      trigger: 'blur'
    }],
    international_classification: [{
      required: true,
      message: '请输入',
      trigger: 'blur'
    }],
  }
  modifyInsNumber = {} as IInsNumberDetail
  importLoading = false

  mounted() {
    this.getEnterpriseDetail()
    this.getRoles()
  }

  created() {
    const name = this.$route.params.enterpriseName
    if (!name) {
      // @ts-ignore
      this.enterpriseName = UserModule.user?.enterprise?.name
    } else {
      this.enterpriseName = name
    }
  }

  handlePreview(_: any) {
  }

  handleRemove(_: any, __: any) {
  }

  async handleChange(file: any, _: any) {
    this.importLoading = true
    const excel = file
    if (!excel) return
    insNumberApi.importInsNumbers(excel.raw).then((data: any) => {
      this.$message.success('导入成功')
      this.importLoading = false
      this.getInsNumbers()
    }).catch((e) => {
      this.$message.error(e)
      this.importLoading = false
    })
  }

  deleteInsNumber(params: IInsNumberDetail) {
    this.$confirm(`是否继续删除仪器编号 ${params.number}?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      insNumberApi.deleteInsNumber(params.id).then(() => {
        this.$message.success('删除成功')
        this.getInsNumbers()
      }).catch((e) => {
        this.$message.error(`删除失败, ${e.message}`)
      })
    })
  }

  doModifyInsNumber(params: IInsNumberDetail) {
    this.action = '修改'
    this.modifyInsNumber = params
    this.addInsNumberParams = {
      ...this.modifyInsNumber,
      international_number: params.internationalNumber,
      international_classification: params.internationalClassification
    }
    this.addInsNumberVisible = true
  }

  submitAddInsNumber() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.addInsNumber.validate((valid: boolean) => {
      if (!valid) {
        this.$message.warning('请检查表单')
        return
      }
      this.addInsNumberLoading = true
      if (this.action === '修改') {
        insNumberApi
          .modifyInsNumber(this.modifyInsNumber.id, this.addInsNumberParams)
          .then(() => {
            this.$message.success('修改成功')
            this.addInsNumberLoading = false
            this.addInsNumberVisible = false
            this.getInsNumbers()
          })
          .catch((e) => {
            this.$message.error(`修改失败, ${e.message}`)
            this.addInsNumberLoading = false
          })
      } else {
        insNumberApi
          .createInsNumber(this.addInsNumberParams)
          .then(() => {
            this.$message.success('创建成功')
            this.addInsNumberLoading = false
            this.addInsNumberVisible = false
            this.getInsNumbers()
          })
          .catch((e) => {
            this.$message.error(`创建失败, ${e.message}`)
            this.addInsNumberLoading = false
          })
      }
    })
  }

  cancelAddInsNumber() {
    this.addInsNumberVisible = false
    this.addInsNumberParams = {} as ICreateInsNumber
  }

  showEditInsNumber() {
    this.insNumberVisible = true
    this.getInsNumbers()
  }

  getInsNumbers() {
    this.insNumberLoading = true
    insNumberApi.getInsNumber(this.insNumberParams).then((data) => {
      this.insNumbers = data
      this.insNumberLoading = false
    }).catch((e) => {
      this.$message.warning(e)
      this.insNumberLoading = false
    })
  }

  get PERMISSION() {
    return PERMISSION
  }

  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin
  }

  get uploadUrl(): string {
    return process.env.VUE_APP_BASE_API + '/image'
  }

  get imageUrl(): string {
    if (this.enterpriseDetail.logo && this.enterpriseDetail.logo.length > 0) {
      return this.uploadUrl + '/' + this.enterpriseDetail.logo
    }
    return ''
  }

  get formatterRoles() {
    // @ts-ignore
    return (ids) =>
      this.roles
        // @ts-ignore
        ?.filter((child) => ids?.includes(child.id))
        // @ts-ignore
        ?.map((child) => child.name)
        ?.join('、')
  }

  openSort() {
    // @ts-ignore
    this.$refs.sortRef.open()
  }

  getRoles() {
    const params = {}
    if (!this.isSuperAdmin) {
      // @ts-ignore
      params.enterpriseId = UserModule?.user?.enterprise?.id
    }
    roleApi
      .getRoles({
        ...params,
        page: 1,
        perPage: 9999,
      } as IRoleParams)
      .then((data) => {
        // @ts-ignore
        this.roles = data?.items
      })
      .catch((e) => {
      })
      .finally(() => {
      })
  }

  getEnterpriseDetail() {
    this.loading = true
    enterpriseApi
      .getEnterpriseDetail(this.enterpriseName)
      .then((data) => {
        this.enterpriseDetail = {
          ...data,
        }
        // @ts-ignore
        this.repairRoles = this.enterpriseDetail.repairRoles
      })
      .catch((e) => {
        this.$message.error(`获取企业详情失败, ${e.message}`)
      })
      .finally(() => {
        this.loading = false
      })
  }

  modifyAttrLocal() {
    this.modifyAttr = true
    this.lastAttrs = JSON.parse(JSON.stringify(this.enterpriseDetail.insAttrs))
  }

  modifyAttrLocalCancel() {
    this.modifyAttr = false
    this.enterpriseDetail.insAttrs = JSON.parse(JSON.stringify(this.lastAttrs))
  }

  submitModifyAttr() {
    this.modifyLoading = true
    enterpriseApi
      .modifyEnterpriseAttr(this.enterpriseName, this.enterpriseDetail.insAttrs)
      .then(() => {
        this.$message.success('修改成功')
        this.modifyAttr = false
      })
      .catch((e) => {
        this.$message.warning(`修改失败, ${e.message}`)
      })
      .finally(() => {
        this.modifyLoading = false
      })
  }

  addAttr() {
    const attr = {} as IInsEntryCol
    attr.selectAttrs = []
    this.enterpriseDetail.insAttrs.push(attr)
  }

  delAttr(index: number) {
    this.enterpriseDetail.insAttrs.splice(index, 1)
  }

  lock() {
    this.$confirm('锁定企业?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      enterpriseApi
        .lockEnterprise(this.enterpriseDetail.name)
        .then(() => {
          this.$message.success('已锁定')
        })
        .catch((e) => {
          this.$message.error(`锁定失败, ${e.message}`)
        })
        .finally(() => {
          this.getEnterpriseDetail()
        })
    })
  }

  unlock() {
    this.$confirm('解锁企业?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      enterpriseApi
        .unlockEnterprise(this.enterpriseDetail.name)
        .then(() => {
          this.$message.success('已解锁')
        })
        .catch((e) => {
          this.$message.error(`锁定失败, ${e.message}`)
        })
        .finally(() => {
          this.getEnterpriseDetail()
        })
    })
  }

  deleteEnterprise() {
    this.$confirm('删除企业?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      enterpriseApi
        .deleteEnterprise(this.enterpriseDetail.name)
        .then(() => {
          this.$message.success('已删除')
        })
        .catch((e) => {
          this.$message.error(`锁定失败, ${e.message}`)
        })
        .finally(() => {
          this.getEnterpriseDetail()
        })
    })
  }

  showModifyEnterprise() {
    this.enterpriseModify.show(this.enterpriseDetail)
  }

  addSelectAttr(attrs: string[]) {
    this.modifySelectAttrs.push({
      value: '',
      code: ''
    })
  }

  showModifySelectAttrs(attrs: { value: '', code: '' }[]) {
    this.modifySelectAttrs = attrs
    this.visibleSelectAttrs = true
  }

  deleteSelectAttr(index: number) {
    this.modifySelectAttrs.splice(index, 1)
  }

  submitModifySelectAttr() {
    this.visibleSelectAttrs = false
  }

  cancelModifyRepairRoles() {
    this.repairRolesVisible = false
    // @ts-ignore
    this.repairRoles = this.enterpriseDetail.repairRoles
  }

  submitModifyRepairRiles() {
    this.repairRolesLoading = true
    enterpriseApi.modifyEnterprise({...this.enterpriseDetail} as IModifyEnterpriseParam).then(() => {
      this.$message.success('维修角色设置成功')
      this.repairRolesLoading = false
      this.repairRolesVisible = false
    }).catch((e) => {
      this.$message.warning(`维修角色设置失败, ${e}`)
      this.repairRolesLoading = false
    })
  }
}
