













import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import OrderItem from "../order.vue";

@Component({ components: { OrderItem } })
export default class Order extends Vue {
  @Ref("ing") ing!: OrderItem
  @Ref("end") end!: OrderItem
  activeName: string = "ing";
  handleClick() {
    if (this.activeName === "ing") {
      this.ing.onRefresh({})
    }else if (this.activeName === "end") {
      this.end.onRefresh({})
    }
  }
}
