













































































































import { Component, Vue, Ref } from "vue-property-decorator";
import { processApis, IProcessCreate } from "@/api/process";
import { ElForm } from "element-ui/types/form";
import { validProcess } from "@/utils/valid-process";
import { formMarkingToolbar } from "@/utils/options";
import { remoteSelectFunc } from "@/utils/functions";
import { enterpriseApi } from "@/api/enterprise";
// @ts-ignore
import Wfd from "@/components/wfd/components/Wfd.vue";
// @ts-ignore
import { GenerateForm, MakingForm } from "@/components/VueFormMaking";
import "@/components/VueFormMaking/styles/FormMaking.css";
import ace from "ace-builds";
import "ace-builds/webpack-resolver";
Vue.use(ace as any);
Vue.use(MakingForm as any);
Vue.use(GenerateForm as any);

@Component({ components: { Wfd } })
export default class extends Vue {
  // @ts-ignore
  @Ref("makingform") makingform!: MakingForm;
  // @ts-ignore
  @Ref("wfd") wfd!: Wfd;
  @Ref("base-form") baseForm!: ElForm;

  private dialogVisible = false;
  private createLoading = false;
  private errors = {};
  private formMarkingToolbar = formMarkingToolbar;
  private processStructure = {};
  enterprises: any = {};
  // todo: siteId
  private process: IProcessCreate = {
    name: "",
    remarks: "",
    siteId: 0,
    structure: {},
    form: { name: "", remarks: "", structure: {} },
    // @ts-ignore
    isAddHistory: true,
  };
  getEnterprises() {
    enterpriseApi
      .getEnterprises({ page: 1, perPage: 9999 })
      .then((data) => {
        this.enterprises = data;
      })
      .catch((e) => {})
      .finally(() => {});
  }

  private remoteSelectFunc = remoteSelectFunc;

  private wfdRefreshTag = true;
  private readonly rules = {
    name: [{ required: true, message: "请输入模版名称", trigger: "blur" }],
    remarks: [{ required: true, message: "请输入模板说明", trigger: "blur" }],
  };

  private importVisible = false;
  private importData = "";

  private setJson(data: Object) {
    this.$nextTick(() => {
      this.makingform.setJSON(data);
    });
  }

  private refreshWfd() {
    this.wfdRefreshTag = false;
    this.$nextTick(() => {
      this.wfdRefreshTag = true;
    });
  }

  private async submit() {
    const ok = await this.baseForm.validate();
    if (!ok) {
      this.$message.error("表单校验失败，请检查数据");
      return;
    }

    // 验证流程的数据
    const processData = this.wfd.graph.save();
    const error = validProcess(processData);
    if (error) {
      this.$message.error(error);
      return;
    }
    this.process.structure = processData;
    // 验证表单的数据
    const formData = this.makingform.getJSON();
    this.process.form.structure = formData;
    // 提交数据
    this.createLoading = true;
    processApis
      .create(this.process)
      .then(() => {
        this.$notify.success("新建审批流成功");
        this.baseForm.resetFields();
        this.$nextTick(() => {
          this.makingform.handleClear();
        });
        this.refreshWfd();
        this.$emit("refresh", 1);
        this.dialogVisible = false;
      })
      .catch((err) => {
        this.$message.error(err.message + JSON.stringify(err.params));
        this.errors = err.params;
      })
      .finally(() => {
        this.createLoading = false;
      });
  }

  show() {
    this.dialogVisible = true;
    this.refreshWfd();
    this.getEnterprises();
  }

  private importProcess() {
    this.processStructure = JSON.parse(this.importData);
    this.refreshWfd();
    this.importData = "";
    this.importVisible = false;
  }
}
