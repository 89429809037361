
















import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator'
import OrderItem from '../order.vue'
import InsItem from '../index.vue'

@Component({
  components: {
    OrderItem,
    InsItem
  }
})
export default class RangeCheck extends Vue {
  @Ref('ing') ing!: OrderItem
  @Ref('end') end!: OrderItem
  @Ref('wait') wait!: InsItem
  activeName: string = 'wait'

  handleClick() {
    if (this.activeName === 'ing') {
      this.ing.onRefresh({})
    } else if (this.activeName === 'end') {
      this.end.onRefresh({})
    } else if (this.activeName === 'wait') {
      this.wait.onRefresh({})
    }
  }
}
