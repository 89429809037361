





























































































import { Component, Prop, Vue } from "vue-property-decorator";
import { enterpriseApi, ICreateEnterpriseParam } from "@/api/enterprise";
import { isValidUsername } from "@/utils/validate";

@Component
export default class EnterpriseCreate extends Vue {
  @Prop({ required: true }) afterSuccess!: Function;
  load = false;
  visible = false;
  imageVisible = false;
  params = {} as ICreateEnterpriseParam;
  logoList = [] as { name: string; url: string }[];
  rules = {
    name: [
      { required: true, message: "请输入企业名称", trigger: "blur" },
      { min: 3, max: 128, message: "长度在 3 到 128 个字符", trigger: "blur" },
    ],
    address: [{ required: true, message: "请输入地址", trigger: "blur" }],
    telephone: [
      {
        type: "string",
        required: true,
        message: "请输入联系人电话",
        trigger: "blur",
      },
      // { min: 11, max: 15, message: "长度在 11 到 15 个字符", trigger: "blur" },
      {
        validator: this.validateUsername,
        message: "请输入正确登录名称",
        trigger: "blur",
      },
    ],
    telephoneOwner: [
      {
        type: "string",
        required: true,
        message: "请输入联系人",
        trigger: "blur",
      },
    ],
  };

  validateUsername(rule: any, value: string, callback: Function) {
    if (!isValidUsername(value)) {
      callback(new Error("请输入正确登录名名"));
    } else {
      callback();
    }
  }
  show() {
    this.visible = true;
  }

  trimStr(value: string) {
    this.params.name = value.replace(/\s+/g, "");
  }
  doCreate() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.$refs.createEnterprise.validate((valid: boolean) => {
      if (!valid) {
        this.$message.warning("请检查表单");
        return;
      }
      this.load = true;
      this.params.name = this.params.name.replace(/\s+/g, "");
      enterpriseApi
        .createEnterprise(this.params)
        .then(() => {
          this.$message.success("创建成功");
          this.load = false;
          this.visible = false;
          this.afterSuccess();
        })
        .catch((e) => {
          this.$message.error(`创建失败, ${e.message}`);
          this.load = false;
        });
    });
  }

  get uploadUrl(): string {
    return process.env.VUE_APP_BASE_API + "/image";
  }

  beforeUpload(file: File) {
    if (file.type !== "image/jpeg" && file.type !== "image/png") {
      this.$message.error("格式只能是JPG格式");
      return false;
    }
    if (file.size / 1024 / 1024 > 5) {
      this.$message.error("不得超过5M");
      return false;
    }
  }

  handleSuccess(res: any) {
    if (!res.success) {
      this.$message.error(`上传图片失败, ${res.message}`);
      return;
    }
    this.params.logo = res.data;
    this.logoList = [
      { url: this.uploadUrl + "/" + res.data, name: res.data.split("/")[-1] },
    ];
    this.$forceUpdate();
  }

  handleImageShow() {
    this.imageVisible = true;
  }

  handleRemove() {
    this.params.logo = "";
    this.logoList = [] as { name: string; url: string }[];
    this.$forceUpdate();
  }

  mounted() {
    //
  }
}
