































import { Component, Vue, Prop } from 'vue-property-decorator'
// @ts-ignore
import * as _ from 'lodash'
@Component
export default class extends Vue {
  @Prop({ default: '' }) private queryString!: string
  @Prop({ default: false }) private showAdd!: boolean
  @Prop({ default: false }) private showSync!: boolean
  @Prop({ default: false }) private queryLoading!: boolean
  @Prop({ default: '' }) private addPerm!: any
  @Prop({ default: '请输入关键字搜索' }) private placeholder!: string

  get q() {
    return this.queryString
  }

  set q(value) {
    this.$emit('update:query-string', value)
  }

  get lodashRrfresh() {
    return _.debounce(this.handleRrfresh, 250)
  }

  handleRrfresh() {
    this.$emit('refresh', this.q)
  }

  handleAdd() {
    this.$emit('add', 1)
  }

  handSync() {
    this.$emit('sync', 1)
  }
}
