






















































































import { Component, Ref, Vue } from "vue-property-decorator";
import {
  enterpriseApi,
  IEnterprises,
  IGetEnterprisesParam,
} from "@/api/enterprise";
import EnterpriseCreate from "@/views/enterprise/EnterpriseCreate.vue";
import PERMISSION from "@/constants/permission";
import { UserModule } from "@/store/modules/user";

@Component({ components: { EnterpriseCreate } })
export default class Enterprises extends Vue {
  @Ref("enterprise-create") enterpriseCreate!: EnterpriseCreate;
  queryLoad = false;
  enterprises = {} as IEnterprises;
  showAllQuery = false;
  queryParams = { page: 1, perPage: 15 } as IGetEnterprisesParam;
  condition: any = {};
  mounted() {
    // this.getEnterprises();
  }

  get PERMISSION() {
    return PERMISSION;
  }

  get searchStyle() {
    if (!this.queryLoad) {
      return "";
    }
    return "margin-left: 59%;";
  }

  get extraConfig() {
    return [
      {
        key: "telephoneOwner",
        label: "联系人",
        type: "input",
        placeholder: "请输入联系人",
      },

      {
        key: "telephone",
        label: "电话",
        type: "input",
        placeholder: "请输入联系人电话",
      },
      {
        key: "status",
        label: "状态",
        type: "select",
        options: [
          {
            value: "0",
            label: "初始化",
          },
          {
            value: "1",
            label: "使用中",
          },
          {
            value: "2",
            label: "锁定",
          },
          {
            value: "3",
            label: "废弃",
          },
        ],
        placeholder: "请选择状态",
      },
    ];
  }
  onRefresh(condition: any) {
    this.condition = condition;
    this.getEnterprises();
  }

  getEnterprises() {
    this.queryLoad = true;
    enterpriseApi
      .getEnterprises({
        ...this.queryParams,
        ...this.condition,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      })
      .then((data) => {
        this.enterprises = data;
        this.setRoute();
      })
      .catch((e) => {
        this.$message.warning(e);
      })
      .finally(() => {
        this.queryLoad = false;
      });
  }

  setRoute() {
    // 请使用该方式添加参数，不然会有问题
    // @ts-ignore
    this.$router.push({ query: { ...this.$route.query, ...this.queryParams } });
  }

  resetParams() {
    this.queryParams = { page: 1, perPage: 15 } as IGetEnterprisesParam;
    this.getEnterprises();
  }

  handleSizeChange(size: number) {
    this.queryParams.perPage = size;
    this.getEnterprises();
  }

  handlePageChange(page: number) {
    this.queryParams.page = page;
    this.getEnterprises();
  }

  createEnterprise() {
    this.enterpriseCreate.show();
  }

  toEnterpriseDetail(enterpriseName: string) {
    this.$router.push(`/enterprise/${enterpriseName}`);
  }
}
