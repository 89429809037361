import { render, staticRenderFns } from "./SortFields.vue?vue&type=template&id=d4512e3e&scoped=true&"
import script from "./SortFields.vue?vue&type=script&lang=ts&"
export * from "./SortFields.vue?vue&type=script&lang=ts&"
import style0 from "./SortFields.vue?vue&type=style&index=0&id=d4512e3e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4512e3e",
  null
  
)

export default component.exports