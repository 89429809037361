var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container"},[_c('SearchHeader',{attrs:{"query-string":_vm.params.q,"query-loading":_vm.loading,"show-add":true},on:{"update:queryString":function($event){return _vm.$set(_vm.params, "q", $event)},"update:query-string":function($event){return _vm.$set(_vm.params, "q", $event)},"refresh":_vm.getItems,"add":function($event){return _vm.processCreate.show()}}}),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"data":_vm.result.items}},[_c('el-table-column',{attrs:{"label":"#","prop":"id","width":"80"}}),_c('el-table-column',{attrs:{"label":"流程名称","prop":"name"}}),_c('el-table-column',{attrs:{"label":"创建人","prop":"create_id","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatterUser(row.create_id))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"企业","prop":"enterprise_id","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm.formatterEns(row.enterprise_id))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"创建时间","prop":"createTime","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.create_time)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"最近修改时间","prop":"modifiedTime","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.modified_time)+" ")]}}])}),_c('el-table-column',{attrs:{"width":"100","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-link',{on:{"click":function($event){return _vm.$router.push({
              name: 'process-detail',
              query: { processId: row.id },
            })}}},[_vm._v(" 查看详情"),_c('i',{staticClass:"el-icon-edit el-icon--right"})])]}}])})],1),_c('Pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.result.total > 0),expression:"result.total > 0"}],attrs:{"total":_vm.result.total,"page":_vm.params.page,"limit":_vm.params.perPage},on:{"update:page":function($event){return _vm.$set(_vm.params, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.params, "perPage", $event)},"pagination":_vm.getItems}}),_c('ProcessCreate',{ref:"process-create",on:{"refresh":_vm.getItems}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }