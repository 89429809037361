
































































































































































































import { Component, Vue } from "vue-property-decorator";
import { UserModule } from "@/store/modules/user";
import { fileApi } from "@/api/files";
import PERMISSION from "@/constants/permission";
import { convertRes2Blob } from "@/utils/download";

@Component
export default class Files extends Vue {
  private params = { page: 1, perPage: 15 };
  private files = {};
  private loading = false;
  private visible = false;
  private createModifyLoad = false;
  private title = "新增仪器组";
  private openType = "create";
  private groupFields = [];
  private openLoading = false;
  private createModifyParams: any = {
    brand: "",
    model: "",
    type: "url",
    files: [],
  };
  private departments = {};
  private enterprises = {};
  private users = {};
  currentRow = {};
  downloadVisible = false;
  condition: any = {};
  private rules = {
    model: [{ required: true, message: "请输入型号", trigger: "blur" }],
    brand: [{ required: true, message: "请输入品牌", trigger: "blur" }],
    type: [{ required: true, message: "请选择文件类型", trigger: "blur" }],
    url: [{ required: true, message: "请输入文件地址", trigger: "blur" }],
    name: [{ required: true, message: "请输入文件名称", trigger: "blur" }],
    files: [{ required: true, message: "请上传文件", trigger: "blur" }],
  };

  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }
  get formatterName() {
    return (row: any) => {
      return row?.files?.map((file: any) => file.name).join("、");
    };
  }
  get PERMISSION() {
    return PERMISSION;
  }
  get extraConfig() {
    return [
      {
        key: "brand",
        label: "品牌",
        type: "input",
        placeholder: "请输入品牌",
      },
      {
        key: "model",
        label: "型号",
        type: "input",
        placeholder: "请输入型号",
      },
    ];
  }
  download(row: any) {
    this.currentRow = row;
    this.downloadVisible = true;
  }
  async onView(file: any) {
    if (file.type === "url") {
      window.open(file.url);
    } else {
      const response = await fileApi.download(file.url);
      convertRes2Blob(response, file.name);
    }
  }
  onRefresh(condition: any) {
    this.condition = condition;
    this.getFiles();
  }
  private async onOpen(row: any, type = "create") {
    // this.openType = type;
    // this.openLoading = true;
    // this.openLoading = false;
    this.title = "新增文件";
    this.visible = true;
    this.createModifyParams = {
      brand: "",
      model: "",
      type: "url",
      files: [],
      url: "",
      name: "",
    };
  }
  private async onDelete(row: any) {
    this.$confirm(`是否继续删除文件 ${this.formatterName(row)}?`, "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        fileApi.delete(row.id).then(() => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getFiles();
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }
  private async getFiles() {
    this.loading = true;
    try {
      const res = await fileApi.getFiles({
        ...this.params,
        ...this.condition,
      });
      this.files = res;
    } catch (error) {}
    this.loading = false;
  }

  private doAction() {
    // @ts-ignore
    this.$refs.createModifyRef.validate(async (valid: boolean) => {
      if (!valid) {
        this.$message.warning("请检查表单");
        return;
      }
      this.createModifyLoad = true;
      const { brand, model, type, url, files, name } = this.createModifyParams;
      const params: any = {
        brand,
        model,
      };
      const filesR = files.map((file: any) => fileApi.upload(file.raw));
      const filesUrl = await Promise.all(filesR);
      // return;
      if (type === "url") {
        params.files = [
          {
            type,
            name,
            url,
          },
        ];
      } else {
        params.files = filesUrl.map((url, index) => ({
          type,
          url,
          name: files[index].name,
        }));
      }
      fileApi
        .createFiles(params)
        .then(() => {
          this.$message.success("新增成功");
          this.visible = false;
          this.getFiles();
        })
        .catch((e) => {
          this.$message.warning(`新增失败, ${e}`);
        })
        .finally(() => {
          this.createModifyLoad = false;
        });
    });
  }

  private created() {
    // this.getFiles();
  }
  handleSizeChange(size: number) {
    this.params.perPage = size;
    this.getFiles();
  }

  handlePageChange(page: number) {
    this.params.page = page;
    this.getFiles();
  }
  handlePreview(file: any) {
    // console.log(file);
  }
  handleRemove(file: any, fileList: any) {
    // console.log(file, fileList);
  }
  handleChange(file: any, fileList: any) {
    this.createModifyParams.files = fileList;
  }
}
